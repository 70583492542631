<template>
    <div class="page">
        <div class="top ">
            <van-cell :title="!info.linkman?'无名氏':info.linkman">
                <div slot="default">
                    <span class="text-theme1">{{info.activity_name}}</span>
                    <span class="text-theme3">￥{{info.amount}}</span>
                </div>
            </van-cell>
        </div>

        <van-cell-group>
            <van-cell title="订单状态">
                <div slot="default">
                    <div class="left"></div>
                    <div class="right text-theme3">{{info.pay_status_note}}</div>
                </div>
            </van-cell>
            <van-cell title="创建时间">
                <div slot="default">
                    <div class="left">{{info.createtime}}</div>
                </div>
            </van-cell>
            <van-cell title="报名信息">
                <div slot="default">
                    <div class="left">{{info.linkman}}({{info.phone}})</div>
                </div>
            </van-cell>
            <van-cell title="备注" value-class="flex-5">
                <div slot="default">
                    <div class="left">{{info.note}}</div>
                </div>
            </van-cell>
        </van-cell-group>

        <div class="footer">
            <van-button type="info" class="mr10" block @click="commit">提交验票</van-button>
        </div>

    </div>
</template>
<script>
    import {
        Button,Cell, CellGroup,Tag
    } from "vant";

    import {get_ticket_detail,ver_ticker} from '@/request/api';
    import wx from "@/request/utils/wechat";//微信JSSDK

    export default {

        components: {
            [Button.name]: Button,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tag.name]: Tag,
        },
        data() {
            return {
                info:false,
                code:'',//扫码得到的code,
				activity_id: ''
            };
        },
        mounted() {
            this.code = this.$route.query.code;
			this.activity_id = this.$route.query.activity_id
            this.initData();
        },
        methods: {
            initData(){
                //1.获取活动详情
                get_ticket_detail({code: this.code}).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    } else {
                        this.$dialog({message: res.msg}).then(() => {
                            this.$router.go(-1);
                        }).catch(error=>{
                            this.$router.go(-1);
                        });
                    }
                }).catch(error=>{

                });
            },
            commit:function () {
                if (!this.info){
                    this.$dialog({message: "数据不存在"});return;
                }
                this.$dialog.confirm({
                    title: '提示',
                    message: '确认验票吗'
                }).then(() => {
                    //提交验票
                    ver_ticker({keyword:this.info.id,aid: this.activity_id}).then(res=>{
                        if(res.code==1){
                            this.$dialog.confirm({
                                title: '提示',
                                message: '验票成功'
                            }).then(() => {
                                this.$router.go(-1);
                            });
                        }else{
                            this.$toast.fail(res.msg);
                        }
                    }).catch(error=>{
                        this.$toast.fail("验票失败");
                    });
                }).catch(() => {
                    // on cancel
                });
            }
        }
    };
</script>
<style lang="less">
    .footer {
        padding: 0.5rem;
    }

</style>